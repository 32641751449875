<template>
    <v-data-table
      :headers="headers"
      :items="imports"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': loading,
        'disable-items-per-page': loading,
      }"
      class="seller-products-table unset-table-hover"
      :items-per-page="globalItemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      @update:page="getImports"
      @update:sort-by="getImports"
  >
    <template #[`item.imported`]="{item}">
      {{ item.imported | value }}
    </template>
    <template #[`item.skipped`]="{item}">
      {{ item.skipped | value }}
    </template>
    <template #[`item.error`]="{item}">
      {{ item.error | value }}
    </template>
    <template #[`item.status`]="{item}">
      <v-chip
          small
          :color="statusColor[status[item.status]]"
          :class="`${statusColor[status[item.status]]}--text`"
          class="v-chip-light-bg"
      >
        {{ status[item.status] }}
      </v-chip>
    </template>
    <template #[`item.access`]="{item}">
      <delete-import :id="item.id" :is-dialog-visible="openDelete[item.id]!==undefined?openDelete:stopTrigger" @importDeleted="getImports()" />
      <v-btn dark icon plain @click="deleteImport(item.id)" class="trash">
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.999959 13.8333C0.999959 14.75 1.74996 15.5 2.66663 15.5H9.33329C10.25 15.5 11 14.75 11 13.8333V3.83333H0.999959V13.8333ZM2.66663 5.5H9.33329V13.8333H2.66663V5.5ZM8.91663 1.33333L8.08329 0.5H3.91663L3.08329 1.33333H0.166626V3H11.8333V1.33333H8.91663Z" fill="#3A3541" fill-opacity="0.54"/>
        </svg>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import {mdiSquareEditOutline, mdiMenuDown} from '@mdi/js';
import {ref, onMounted, watch, computed} from "@vue/composition-api";
import {useVModel} from '@vueuse/core';
import DeleteImport from "@/views/cabinet/product/DeleteImport";
import store from '@/store';
import axios from "@axios";
import {useRouter} from '@core/utils'

export default {
  components: {
    DeleteImport
  },
  filters: {
    value(value) {
      return !value ? '-' : value
    },
  },
  setup() {
    const {route, router} = useRouter()
    const options = computed(({
      get() {
        return store.state.pagination.products[route.value.params.tab]
      },
      set(val) {
        let ob = {}
        let page = val.page > val.lastPage ? 1 : val.page
        ob[route.value.params.tab] = Object.assign({}, val, {
          page
        })
        store.commit("pagination/SET_PRODUCTS_PAGINATION", ob);
      }
    }))
    const loading = ref(true)
    const uploads = ref([])
    const totalUploads = ref(0)
    const stopTrigger = ref({})
    const openDelete = ref({})
    const deleteImport = (id) => {
      let num = openDelete.value[0] !== undefined ? openDelete.value[0] + 1 : 1
      openDelete.value = {[id]: num}
    }

    const getImports = () => {
      store.dispatch('imports/getImports')
    }

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])
    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e)
      getImports()
    }

    getImports()

    return {
      updateItemsPerPage,
      globalItemsPerPage,
      getImports,
      stopTrigger,
      openDelete,
      deleteImport,
      options,
      headers: [
        {text: 'Date', value: 'created_at', width: 115, sortable: false},
        {text: 'Document\'s name', value: 'name', width: 85, sortable: false},
        {text: 'Total ASINs', value: 'total', width: 90, sortable: false},
        {text: 'Imported', value: 'imported', width: 90, sortable: false},
        {text: 'Skipped', value: 'skipped', width: 80, sortable: false},
        {text: 'Error', value: 'error', width: 100, sortable: false},
        {text: 'Status', value: 'status', width: 120, sortable: false},
        {text: '', value: 'access', sortable: false, width: 70}
      ],
      imports: computed(() => store.state.imports.imports),
      total: computed(() => store.state.imports.total),
      loading: computed(() => store.state.imports.loading),
      status: {
        1: 'Created',
        2: 'In processed',
        3: 'Done',
        4: 'Failed',
      },
      statusColor: {
        Created: 'warning',
        'In processed': 'blue-grey',
        Done: 'success',
        Failed: 'error',
      },
      icons: {
        mdiSquareEditOutline,
        mdiMenuDown,
      },
    }
  },
}
</script>