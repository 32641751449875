var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"seller-products-table unset-table-hover",attrs:{"headers":_vm.headers,"items":_vm.imports,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': _vm.$store.getters['nav/itemsPerPageOptions'],
      'disable-pagination': _vm.loading,
      'disable-items-per-page': _vm.loading,
    },"items-per-page":_vm.globalItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.getImports,"update:sort-by":_vm.getImports},scopedSlots:_vm._u([{key:"item.imported",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("value")(item.imported))+" ")]}},{key:"item.skipped",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("value")(item.skipped))+" ")]}},{key:"item.error",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("value")(item.error))+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusColor[_vm.status[item.status]]) + "--text"),attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}},{key:"item.access",fn:function(ref){
    var item = ref.item;
return [_c('delete-import',{attrs:{"id":item.id,"is-dialog-visible":_vm.openDelete[item.id]!==undefined?_vm.openDelete:_vm.stopTrigger},on:{"importDeleted":function($event){return _vm.getImports()}}}),_c('v-btn',{staticClass:"trash",attrs:{"dark":"","icon":"","plain":""},on:{"click":function($event){return _vm.deleteImport(item.id)}}},[_c('svg',{attrs:{"width":"12","height":"16","viewBox":"0 0 12 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.999959 13.8333C0.999959 14.75 1.74996 15.5 2.66663 15.5H9.33329C10.25 15.5 11 14.75 11 13.8333V3.83333H0.999959V13.8333ZM2.66663 5.5H9.33329V13.8333H2.66663V5.5ZM8.91663 1.33333L8.08329 0.5H3.91663L3.08329 1.33333H0.166626V3H11.8333V1.33333H8.91663Z","fill":"#3A3541","fill-opacity":"0.54"}})])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }