<template>
  <div v-if="!loading && market" class="saa-products-container">
    <account-role-title role="Store" />
    <!-- <breadcrumbs /> -->
    <ProductsInfo :admin="isAdminRoute" :reload="reload"/>
    <span class="title">{{ $t("Inventory") }}</span>
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows class="deals-tabs">
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        @click="$router.push({name: isAdminRoute ? 'admin-products-user-store-list' : 'seller-products', params: isAdminRoute ? {store_id: $route.params.store_id, tab: tab.route} : {tab: tab.route}, query:{t:new Date().getTime()}})"
      >
        <span>{{ $t(tab.title) }}</span>
      </v-tab>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <div class="d-flex justify-end pr-8">
            <products-search v-if="tab.route !== 'uploaded'" :admin="false"/>
            <!--<upload-product v-if="!isAdminRoute"/>-->
            <create-product v-if="!isAdminRoute"/>
          </div>
          <!-- tab component -->
          <component v-if="tab.route === $route.params.tab" :is="tab.component" @productDeleted="evReload"/>
        </v-tab-item>
      </v-tabs-items>
      <span class="deals-storefront">
      <v-btn v-if="!isAdminRoute" plain color="primary" :to="{name: 'store-products', params: {slug: market.slug}}">
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.24998 0.916992L5.07248 1.73949L2.40081 4.41699H11.8333V5.58366H2.40081L5.07831 8.26116L4.24998 9.08366L0.166646 5.00033L4.24998 0.916992Z" fill="#9155fd"/>
        </svg>
        {{ $t('go to storefront') }}
      </v-btn>
    </span>
    </v-tabs>
  </div>
</template>
<script>
import ProductsSearch from "@/views/cabinet/product/ProductsSearch";
import ProductsInfo from "@/views/cabinet/product/ProductsInfo";
import ProductsTable from "@/views/cabinet/product/ProductsTable";
import UploadedTable from "@/views/cabinet/product/UploadedTable";
import CreateProduct from "@/views/cabinet/product/CreateProduct";
import UploadProduct from "@/views/cabinet/product/UploadProduct";
import { onBeforeMount, ref, watch, computed } from "@vue/composition-api";
import { useRouter } from '@core/utils'
import axios from "@axios";
import store from '@/store';

export default {
  name: "Products",
  components: {
    ProductsSearch,
    CreateProduct,
    ProductsTable,
    ProductsInfo,
    UploadedTable,
    UploadProduct
  },
  setup() {
    const reload = ref(false)
    const tabs = [
      { title: 'All', route: 'all', component: ProductsTable },
      { title: 'Active', route: 'active', component: ProductsTable },
      { title: 'Inactive', route: 'inactive', component: ProductsTable },
      { title: 'Drafts', route: 'drafts', component: ProductsTable },
      { title: 'Rejected', route: 'rejected', component: ProductsTable },
      { title: 'Uploaded', route: 'uploaded', component: UploadedTable }
    ]
    const { route, router } = useRouter()
    const market = computed(() => {
      if(!store.state.market.market) router.push({name: 'user-market', params:{tab:'all'}})
      return store.state.market.market
    })
    const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
    onBeforeMount(() => store.dispatch('market/getMarket'))
    watch(() => route.value.params.tab, (newTab) => tab.value = tabs.findIndex(tab => tab.route === newTab))
    const isAdminRoute = computed(() => route.value.name === 'admin-products-user-store-list')
    return {
      evReload: () => reload.value = !reload.value,
      reload,
      isAdminRoute,
      tab,
      tabs,
      market,
      loading: computed(() => store.state.market.loading),
    }
  }
}
</script>
