<template>
    <v-dialog v-model="isUploadDialogVisible" :max-width="['uk', 'ru'].includes($i18n.locale) ? '635px' : '530px'" content-class="deals-dialog">
        <template v-slot:activator="{ on, attrs }"> 
            <div class="button-container">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :width="112"
                    outlined
                    color="primary"
                >
                    {{ $t('Upload') }}
                </v-btn>
            </div>
        </template>
        <v-card>
            <v-card-title>
                <v-container fluid>
                    <v-row>
                        <v-col cols="3"/>
                        <v-col cols="9">
                            <span class="headline">{{ $t('Upload File') }}</span></br>
                            <span class="deals-dialog-sub-title">{{ $t("Download the document for fast loading of goods.") }}</span></br>
                            <span class="deals-dialog-sub-title"><a @click="download()" style="text-decoration: none">{{ $t("Download") }}</a> {{ $t("document template.") }}</span></br>
                            <span class="deals-warning">{{ $t("Supported file formats: XLS, XLSX.") }}</span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>{{ $t("File to upload") }}</v-subheader>
                        </v-col>
                        <v-col cols="9">
                        <v-btn
                            color="primary"
                            class="text-none"
                            depressed
                            outlined
                            :loading="isUploading"
                            @click="onButtonClick"
                        >
                            {{ $t("Choose file") }}
                        </v-btn>
                        <input
                            type="file"
                            :accept="['.xls', '.xlsx']"
                            ref="uploader"
                            class="d-none"
                            @change="onFileChanged"
                        />
                        <span class="file-chosen" :class="{'file-error': fileError }">{{ $t(fileName) }}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" class="pt-0">
                            <v-subheader>{{ $t("Status") }}</v-subheader>
                        </v-col>
                        <v-col cols="9" class="pb-0 pt-0">
                            <v-select
                                :items="itemsDrop"
                                v-model="selectedStatus"
                                outlined
                                dense
                                @change="statusError = false"
                                :placeholder="$t('Status')"
                                class="custom-width-select"
                                :error="statusError"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="3"/>
                        <v-col cols="9" class="pt-0">
                            <v-checkbox 
                                class="deals-checkbox" 
                                v-model="updateIfIsset"
                                :label="$t('Update existing products')"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" />
                        <v-col cols="9" style="padding-top: 0">
                            <v-btn class="primary" @click="upload()">{{ $t('Upload') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {ref, getCurrentInstance, watch, computed} from '@vue/composition-api'
import axios from "@axios";
import store from "@/store";

export default {
    name: 'UploadProduct',
    setup(props, {refs}) {
        const vm = getCurrentInstance().proxy
        const isUploadDialogVisible = ref(false)
        const fileName = ref('No file chosen')
        const fileError = ref(false)
        const selectedFile = ref(null)
        const selectedStatus = ref(0)
        const isUploading = ref(false)
        const statusError = ref(false)
        const updateIfIsset = ref(false)
        const loading = ref(false)

        watch(() => isUploadDialogVisible.value, (newValue) => {
            //Close Upload Modal
            if(!newValue) {
                fileError.value = updateIfIsset.value = statusError.value = false
                selectedFile.value = null
                selectedStatus.value = 0
                refs.uploader.value = null
            }
        })

        const onButtonClick = () => {
            isUploading.value = true
            window.addEventListener('focus', () => {
                isUploading.value = false
            }, { once: true })
            refs.uploader.click()
        }

        const onFileChanged = (e) => {
            selectedFile.value = e.target.files[0]
            fileError.value = false
        }

        const download = () => {
            axios.get('product-import-example', { responseType: 'blob' }).then((res) => {
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(new Blob([res.data]), { type: res.headers['content-type'] })
                link.setAttribute('download', 'sellerassistant_deals_import.xlsx')
                link.click()
            })
        }

        const upload = () => {
            // Validate
            fileError.value = !selectedFile.value
            statusError.value = !selectedStatus.value && selectedStatus.value !== 0
            if(!!statusError.value || !!fileError.value) {
                return
            }
            let formData = new FormData()
            formData.append("file", selectedFile.value)
            formData.append("status", selectedStatus.value)
            formData.append("update", updateIfIsset.value)
            loading.value = true
            axios.post('product-import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                vm.$notify({
                    group: 'deals',
                    title: 'Import start',
                    type: 'success',
                    text: vm.$t("Products import started"),
                })
                loading.value = false
                isUploadDialogVisible.value = false
                store.dispatch('imports/getImports', {})
            }).catch(e => {
                vm.$notify({
                    group: 'deals',
                    title: 'Upload Error',
                    type: 'error',
                    text: vm.$t("Products import failed"),
                })
                loading.value = false
            })
        }

        return {
            loading,
            updateIfIsset,
            onButtonClick,
            isUploadDialogVisible,
            onFileChanged,
            upload,
            download,
            itemsDrop: [{value: 0, text:'Draft'}, {value: 1, text:'Published'}],
            fileName: computed(() => selectedFile.value ? selectedFile.value.name : fileName.value),
            selectedFile,
            isUploading,
            selectedStatus,
            fileError,
            statusError
        }
    }
}
</script>