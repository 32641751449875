<template>
  <v-dialog
      v-model="isVisible"
      width="500"
  >
    <v-card :loading="deleteInProgress" style="padding: 20px">
      <v-img max-height="300" max-width="300" :src="banner" style="margin: auto"></v-img>
      <v-card-title class="text-center dialog-content">
        {{ $t('Are you sure want to delete all downloaded products?') }}
      </v-card-title>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
                color="primary"
                outlined
                @click="deleteImport()"
                block 
                class="min-btn"
            >
              {{ $t('Remove imported products') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                block
                color="primary"
                @click="deleteImport(false)"
                class="min-btn"
            >
              {{ $t('Remove entry only') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getCurrentInstance, ref, watch} from '@vue/composition-api'
import axios from "@axios";
export default {
  props: {
    isDialogVisible: Object,
    id: Number,
  },
  emits: ['importDeleted'],
  setup(props, {emit}) {
    const vm = getCurrentInstance().proxy
    const isVisible = ref(false)
    const deleteInProgress = ref(false)
    watch(() => props.isDialogVisible, () => {
      if (Object.keys(props.isDialogVisible)[0] == props.id) {
        isVisible.value = true
      }
    }, {deep: true})
    const deleteImport = (remove_products = true) => {
      deleteInProgress.value = true
      axios.post(`delete-product-import/${props.id}`,{_method: 'delete', remove_products: remove_products}).then(res => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Import Deleted'),
          type: 'success',
        })
        isVisible.value = false
        emit('importDeleted')
      }).catch()
    }
    return {
      isVisible,
      deleteInProgress,
      deleteImport,
      banner: require('@/assets/images/app/business-man-depressed-stressed-watching-decrease-graph-stock-financial-trade-market-diagram_1150-39760 1.png')
    }
  },
}
</script>
